//@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import url("https://fonts.googleapis.com/css?family=Cabin+Sketch|Neucha:wght@400;600");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700");
@import url("https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;600");
@import url("https://fonts.googleapis.com/css?family=Oswald:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Lora:regular,bold,italic&subset=latin,latin-ext");
@import url("https://fonts.googleapis.com/css?family=Playfair+Display:regular,bold,italic&subset=latin,latin-ext");
@import url('https://fonts.googleapis.com/css2?family=Expletus+Sans:ital,wght@0,400..700;1,400..700&family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Space+Mono:ital,wght@0,400;0,700;1,400;1,700&display=swap');


// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
//@import "../node_modules/bootstrap/scss/maps";

// Create your own map
$custom-colors: (
  "jetblack": #060606,
  "electricblue": #2a9fd6,
  "hotpink": #e83283,
  "paleblue": #33b7e2,
  "lightorange": #EB686C,
  "luxblack": #1a1a1a,
  "sketchyblack": #555555,
  "slategray": #272b30,
  "slatetext": #aaa,
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);

// 4. Include any optional Bootstrap components as you like
@import "../node_modules/bootstrap/scss/root";
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
//@import "../node_modules/bootstrap/scss/images";
@import "../node_modules/bootstrap/scss/containers";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/card";
@import "../node_modules/bootstrap/scss/bootstrap-utilities";
@import "../node_modules/bootstrap/scss/buttons";
@import "../node_modules/bootstrap/scss/spinners";
@import "../node_modules/bootstrap/scss/pagination";
@import "../node_modules/bootstrap/scss/dropdown";
@import "../node_modules/bootstrap/scss/alert";
@import "../node_modules/bootstrap/scss/forms";
@import "../node_modules/bootstrap/scss/modal";
@import "../node_modules/bootstrap/scss/transitions";
@import "../node_modules/bootstrap/scss/close";


// 5. Add additional custom code here
